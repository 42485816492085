    import * as React from 'react';
    import { ReactComponent as VideoPlay } from '../assets/icons/video-play-small.svg';
    import { ReactComponent as Close } from '../assets/icons/arrow-video-close.svg';
    import { HORIZONTAL_VIDEO_PLAYER_URL } from '../config';
    import { Aspect } from '@sky-tv-group/components';
    import ReactPlayerLoader from '@brightcove/react-player-loader';

    interface ShortHeroBannerProps {
        data: object | any;
    }

    
    const ShortHeroBanner: React.FC<ShortHeroBannerProps> = ({ data }) => {

        console.log(data)


    const [startVideo, setStartVideo] = React.useState(-1);
    const myRefs = React.useRef<Array<HTMLDivElement | null>>([]) as any;

    const showPreview = (e: any, ref: number, show: boolean) => {
    const videoPlayer = myRefs.current[ref].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[0];
    videoPlayer.controls=false;
    videoPlayer.disablePictureInPicture=true;
    videoPlayer.volume=0;
    videoPlayer.muted=true;
    if(show === true) {
        e.target.classList.add("open");
        videoPlayer.play();
        myRefs.current[ref].classList.add("open");
    }
    else {
        e.target.classList.remove("open");
        videoPlayer.pause();
        myRefs.current[ref].classList.remove("open");
    }
    }

    const baseURL = HORIZONTAL_VIDEO_PLAYER_URL.split('/');
    const accountId = baseURL[3];

    return (
    <>
        <div className="nsb-hero bg-lightgrey w-full relative">
        
        {data.backgroundVideoId > 0 ? 
        <div className="absolute top-0 h-[80dvh] max-h-[501px] w-full overflow-hidden">
            <div className="video-background relative h-[80dvh] max-h-[501px]">
                <ReactPlayerLoader accountId={accountId} videoId={data.backgroundVideoId} options={{ autoplay: true, muted: true, loop: true, playsinline: true}} />
            </div>
        </div>
        : 
        <div className="absolute top-0 h-[80dvh] max-h-[501px] w-full overflow-hidden">
            <div className="relative ">
                <img src={data.stillImage.fields.file.url}  alt={data.stillImage.fields.file.title} className="h-[80dvh] max-h-[501px] object-cover w-full" />
            </div>
        </div>
        }
        <div className="flex items-center justify-center h-[80dvh] max-h-[501px] w-screen relative pt-14 z-10 px-4 md:px-0">
                <div className="w-[325px] md:w-auto mx-auto text-center">
                    <h1 className="text-[56px] leading-[60px] font-skyBold font-normal text-white text-center mb-4">{data.heading}</h1>
                        {data.logo?.fields?.file?.url ?
                            <img src={data.logo.fields.file.url} alt={data.logo.fields.file.title} className="max-w-[400px]"/>
                        : null }
                    <div className="text-center py-8">
                        {data.ctaSecondary ? 
                        <>
                            <span className="cursor-pointer mx-auto md:mx-0  text-[16px] inline-block h-[48px] align-top font-skyBold pl-12 pt-[11px] pr-7 bg-blue-pure text-white rounded-full relative mb-4 md:pb-0" onClick={() => setStartVideo(0)}><VideoPlay className="fill-white inline-block absolute left-[8px] md:left-[16px] -mt-[1px]"/> {data.ctaSecondary}</span>
                            <br className="md:hidden"/>
                        </>
                        : <></>}
                        {data.ctaPrimaryLink ? 
                            <a href={data.ctaPrimaryLink} className="mx-auto md:mx-0  text-[16px] inline-block h-[48px] align-top font-skyBold px-6 pt-[11px] bg-white text-blue-pure rounded-full md:ml-6 ">{data.ctaPrimary}</a>
                        : <></>}
                    </div>
                </div>
            </div>
        {startVideo >=0 ? (
            <div className="fixed popover w-full h-full top-0 left-0 flex bg-black items-center">
            <div className="absolute top-0 right-0 mr-2 mt-2 cursor-pointer z-50" onClick={() => setStartVideo(-1)}>
                <Close />
            </div>
            <Aspect ratio={9 / 16}>
                <ReactPlayerLoader
                accountId={accountId}
                videoId={data.videoOrLinkPreviews[startVideo].fields.brightCoveVideoId}
                options={{ autoplay: true }}
                />
            </Aspect>
            <div className="justify-center absolute bottom-[20px] z-[60] w-screen">
                <a href={data.ctaPrimaryLink} className="block h-[48px] w-[250px] text-[16px] align-top font-skyBold px-6 pt-[13px] bg-blue-pure text-white rounded-full mx-auto">{data.ctaPrimary}</a>
            </div>
            </div>
        ) : null }
        </div>
    </>
    );
    };

    export default ShortHeroBanner;
